import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { H1, T, TB } from "../../../components/texts";
import { useUnitDefectHistories } from "../../unit/hooks/useUnitDefectHistories";
import { useTasksBetweenDates } from "../../task/hooks/useTasksBetweenDates";
import { TaskStatus } from "../../../api/core/taskAPI";
import {
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import StandardTableContent from "../../../components/application/StandardTableContent";
import MainArea from "../../../components/layout/MainArea";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import { useUnits } from "../../unit/hooks/useUnits";
import { FormProvider, useForm } from "react-hook-form";
import { DateInput, InputWithLabel } from "../../../components/inputs";
import { CleanButton } from "../../../components/buttons";
import styled from "styled-components";
import { LesserThanIcon } from "../../../components/icons";
import { endOfMonth, formatISO, isSameDay, isSameMonth, startOfMonth } from "date-fns";
import { useSearchParams } from "../../application/hooks/useSearchParams";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import LoadingSpinner from "../../../components/layout/LoadingSpinner";
import { useLocation } from "react-router";
import SelectStyled from "../../../components/application/styles/SelectStyled";
import Select from "react-select";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const groupFilter = (row, columnId, value, addMeta) => {
    if (columnId !== "unit") return true;
    if (!value) return true;
    if (row.depth > 0) return true;

    const { groupId, search } = value;
    let searchMatch = true;
    if (search) {
        searchMatch = fuzzyFilter(row, columnId, search, addMeta);
    }

    let groupMatch = true;
    if (groupId) {
        groupMatch = row.original?.group?.id === groupId;
    }

    return searchMatch && groupMatch;
};

const DowntimeReports = () => {
    const { t } = useTranslation();
    const { getParam, hasParam, setParam, deleteParam } = useSearchParams();
    const fromDate = getParam("from");
    const toDate = getParam("to");

    const unitDefectHistories = useUnitDefectHistories(
        fromDate,
        toDate,
        fromDate != null && toDate != null
    );
    const tasks = useTasksBetweenDates(
        [TaskStatus.COMPLETED, TaskStatus.INVOICED],
        fromDate,
        toDate,
        fromDate != null && toDate != null
    );
    const [columnFilters, setColumnFilters] = useState([]);
    const location = useLocation();
    const isLoading = unitDefectHistories.isLoading || tasks.isLoading;
    const [groupId, setGroupId] = useState(null);

    useEffect(() => {
        if (hasParam("groupId")) {
            const value = getParam("groupId");
            if (value) {
                setGroupId(Number(value));
            } else {
                setGroupId(null);
            }
        } else {
            setGroupId(null);
        }
    }, [location]);

    const methods = useForm();
    const search = methods.watch("search");
    useEffect(() => {
        const newFilter = [];
        newFilter.push({
            id: "unit",
            value: {
                groupId,
                search,
            },
        });
        setColumnFilters(newFilter);
    }, [groupId, search]);

    function updateFromToDate(newDate, key) {
        const formattedDate =
            newDate instanceof Date ? formatISO(newDate, { representation: "date" }) : null;

        if (formattedDate) {
            setParam(key, formattedDate);
        } else {
            deleteParam(key);
        }
    }

    useEffect(() => {
        if (!fromDate && !toDate) {
            updateFromToDate(startOfMonth(new Date()), "from");
            updateFromToDate(endOfMonth(new Date()), "to");
        }
    }, [fromDate, toDate]);

    const units = useUnits();
    const unitGroupOptions = useMemo(() => {
        if (!units?.data) return [];
        const unitGroupMap = new Map();
        for (const unit of units.data) {
            unitGroupMap.set(unit.group.id, {
                label: unit.group.name,
                value: unit.group.id,
            });
        }

        const arr = Array.from(unitGroupMap.values());
        arr.unshift({
            label: t("all_unit_groups"),
            value: null,
        });

        return arr;
    }, [units?.data]);

    const groupIdSelectValue = useMemo(() => {
        if (groupId != null && unitGroupOptions != null) {
            return unitGroupOptions.find(({ value }) => value === groupId) ?? null;
        } else {
            return unitGroupOptions[0];
        }
    }, [groupId, unitGroupOptions]);

    const data = useMemo(() => {
        if (!units?.data) return [];
        if (!unitDefectHistories?.data) return [];
        if (!tasks.data) return [];

        const fromDateParsed = new Date(fromDate);
        if (isNaN(fromDateParsed)) return [];
        const toDateParsed = new Date(toDate);
        if (isNaN(toDateParsed)) return [];

        const downtimes = new Map();
        for (const unit of units.data) {
            downtimes.set(unit.id, {
                ...unit,
                defects: [],
                tasks: [],
            });
        }

        for (const defect of unitDefectHistories.data) {
            const unit = downtimes.get(defect.unit_id);
            if (!unit) continue;

            // For the purpose of this report we simply pretend like the
            // defect was fixed this instant to allow date comparisons
            if (defect.fixed_at == null) defect.fixed_at = new Date().toString();

            const defected_at = new Date(defect.defected_at);
            const fixed_at = new Date(defect.fixed_at);
            unit.defects.push({
                defected_at_trimmed: new Date(Math.max(defected_at, fromDateParsed)),
                fixed_at_trimmed: new Date(Math.min(fixed_at, toDateParsed)),
                defected_at,
                fixed_at,
            });
        }

        for (const task of tasks.data) {
            const unit = downtimes.get(task.unit_id);
            if (!unit) continue;

            task.start = new Date(task.start);
            task.start_trimmed = task.start; // new Date(Math.max(task.start, fromDateParsed));
            task.end = new Date(task.end);
            task.end_trimmed = task.end; // new Date(Math.min(task.end, toDateParsed));

            if (
                unit.defects.some((existingDefect) => {
                    if (
                        task.start_trimmed > existingDefect.defected_at &&
                        task.start_trimmed < existingDefect.fixed_at
                    )
                        return true;
                    if (
                        task.end_trimmed < existingDefect.fixed_at &&
                        task.end_trimmed > existingDefect.defected_at
                    )
                        return true;
                    return false;
                })
            ) {
                continue;
            }

            unit.tasks.push(task);
        }

        return Array.from(downtimes.values());
    }, [units?.data, unitDefectHistories?.data, tasks?.data]);

    const columns = useMemo(
        () => [
            {
                id: "unit",
                header: t("unit"),
                accessorFn: (row) => `${row.int_id} ${row.manufacturer} ${row.type}`,
                filterFn: "group",
            },
            {
                id: "defect",
                className: "summed",
                header: ({ table }) => {
                    const { rows } = table.getFilteredRowModel();
                    let calculatedDowntime = 0;
                    for (const row of rows) {
                        const { original } = row;
                        const { defects } = original;
                        if (!defects) continue;
                        for (const defect of defects) {
                            calculatedDowntime +=
                                Math.abs(defect.fixed_at_trimmed - defect.defected_at_trimmed) /
                                36e5;
                        }
                    }

                    return (
                        <>
                            <span>{t("defect")}</span>
                            <div>({Math.round(calculatedDowntime).toFixed(0)})</div>
                        </>
                    );
                },
                accessorFn: (row) => {
                    let calculatedDowntime = 0;
                    if (row.defects) {
                        for (const defect of row.defects) {
                            calculatedDowntime +=
                                Math.abs(defect.fixed_at_trimmed - defect.defected_at_trimmed) /
                                36e5;
                        }
                    }

                    return `${Math.round(calculatedDowntime).toFixed(0)}${t("hours_unit_symbol")}`;
                },
            },
            {
                id: "service",
                className: "summed",
                header: ({ table }) => {
                    const { rows } = table.getFilteredRowModel();
                    let calculatedDowntime = 0;
                    for (const row of rows) {
                        const { original } = row;
                        const { tasks } = original;
                        if (!tasks) continue;
                        for (const task of tasks) {
                            calculatedDowntime +=
                                Math.abs(task.end_trimmed - task.start_trimmed) / 36e5;
                        }
                    }

                    return (
                        <>
                            <span>{t("service")}</span>
                            <div>({Math.round(calculatedDowntime).toFixed(0)})</div>
                        </>
                    );
                },
                accessorFn: (row) => {
                    let calculatedDowntime = 0;
                    if (row.tasks) {
                        for (const task of row.tasks) {
                            calculatedDowntime +=
                                Math.abs(task.end_trimmed - task.start_trimmed) / 36e5;
                        }
                    }

                    return `${Math.round(calculatedDowntime).toFixed(0)}${t("hours_unit_symbol")}`;
                },
            },
            {
                id: "sum_hours",
                className: "summed",
                header: ({ table }) => {
                    const { rows } = table.getFilteredRowModel();
                    let calculatedDowntime = 0;
                    for (const row of rows) {
                        const { original } = row;
                        const { tasks } = original;
                        const { defects } = original;
                        if (tasks) {
                            for (const task of tasks) {
                                calculatedDowntime +=
                                    Math.abs(task.end_trimmed - task.start_trimmed) / 36e5;
                            }
                        }

                        if (defects) {
                            for (const defect of defects) {
                                calculatedDowntime +=
                                    Math.abs(defect.fixed_at_trimmed - defect.defected_at_trimmed) /
                                    36e5;
                            }
                        }
                    }

                    // return `${t("sum_hours")} (${Math.round(calculatedDowntime).toFixed(0)})`;
                    return (
                        <>
                            <span>{t("sum_hours")}</span>
                            <div>({Math.round(calculatedDowntime).toFixed(0)})</div>
                        </>
                    );
                },
                accessorFn: (row) => {
                    let calculatedDowntime = 0;
                    if (row.tasks) {
                        for (const task of row.tasks) {
                            calculatedDowntime += task.duration;
                        }
                    }

                    if (row.defects) {
                        for (const defect of row.defects) {
                            calculatedDowntime +=
                                Math.abs(defect.fixed_at_trimmed - defect.defected_at_trimmed) /
                                36e5;
                        }
                    }

                    return `${Math.round(calculatedDowntime).toFixed(0)}${t("hours_unit_symbol")}`;
                },
            },
            {
                header: () => null,
                id: "expandButton",
                cell: ({ row }) => {
                    return row.getCanExpand() ? (
                        <CleanButton onClick={row.getToggleExpandedHandler()}>
                            <Chevron $isExpanded={row.getIsExpanded()} />
                        </CleanButton>
                    ) : null;
                },
            },
        ],
        []
    );

    const renderRowSubComponent = useCallback(({ row }) => {
        const { start, start_trimmed, end, end_trimmed, type } = row.original;
        const duration = Math.round(Math.abs(end_trimmed - start_trimmed) / 36e5).toFixed(0);
        const dateDiff = (() => {
            if (isSameDay(start, end)) {
                return `${start.toLocaleString("no", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                })} - ${end.toLocaleString("no", {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;
            }

            if (isSameMonth(start, end)) {
                const startDay = start.getDate();
                const month = start.getMonth() + 1;
                return `${startDay < 10 ? `0${startDay}` : startDay}.${month < 10 ? `0${month}` : month} - ${end.toLocaleString(
                    "no",
                    {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                    }
                )}`;
            }

            return `${start.toLocaleString("no", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
            })} - ${end.toLocaleString("no", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
            })}`;
        })();

        return (
            <tr>
                <td>
                    <T style={{ marginLeft: "2rem" }}>
                        <b>{t(type)}:</b> {dateDiff}
                    </T>
                </td>
                {type === "service" ? (
                    <>
                        <td>-</td>
                        <td>
                            {duration} {t("hours_unit_symbol")}
                        </td>
                    </>
                ) : (
                    <>
                        <td>
                            {duration} {t("hours_unit_symbol")}
                        </td>
                        <td>-</td>
                    </>
                )}
                <td></td>
                <td></td>
            </tr>
        );
    }, []);

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 20,
            },
        }),
        []
    );
    const table = useReactTable({
        columns,
        data,
        initialState,
        state: {
            columnFilters,
        },
        filterFns: {
            group: groupFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        getSubRows: (row) => {
            if (!!row.isSubRow) return [];

            const downtimes = row.tasks
                .map(({ start, start_trimmed, end, end_trimmed }) => ({
                    start,
                    start_trimmed,
                    end,
                    end_trimmed,
                    type: "service",
                    isSubRow: true,
                }))
                .concat(
                    row.defects.map(
                        ({ defected_at, defected_at_trimmed, fixed_at, fixed_at_trimmed }) => ({
                            start: defected_at,
                            start_trimmed: defected_at_trimmed,
                            end: fixed_at,
                            end_trimmed: fixed_at_trimmed,
                            type: "defect",
                            isSubRow: true,
                        })
                    )
                );
            downtimes.sort((a, b) => {
                if (a.start > b.start) {
                    return 1;
                }

                if (a.start > b.start) {
                    return -1;
                }

                return 0;
            });

            return downtimes;
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <>
            <MainArea>
                <H1>{t("downtime")}</H1>

                <FormProvider {...methods}>
                    <form>
                        <InputWithLabel
                            name="search"
                            label={t("searchforunit")}
                            style={{ maxWidth: "27rem" }}
                        />
                        <SelectStyled
                            style={{
                                maxWidth: "27rem",
                                marginTop: "0.2rem",
                                marginBottom: "0.2rem",
                            }}
                        >
                            <Select
                                value={groupIdSelectValue}
                                onChange={(e) => {
                                    if (e.value == null) {
                                        deleteParam("groupId");
                                    } else {
                                        setParam("groupId", e.value);
                                    }
                                }}
                                options={unitGroupOptions}
                                classNamePrefix="rs"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            />
                        </SelectStyled>
                        <DateInputs>
                            <DateInputContainer>
                                <DateInput
                                    name="from"
                                    label={t("from")}
                                    value={fromDate ? new Date(fromDate) : null}
                                    setValue={(v) => updateFromToDate(v, "from")}
                                    maxContainerWidth={"30rem"}
                                    minDate={new Date(2010, 0, 1)}
                                    maxDate={new Date(2049, 11, 31)}
                                />
                            </DateInputContainer>

                            <DateInputContainer>
                                <DateInput
                                    name="to"
                                    label={t("to")}
                                    value={toDate ? new Date(toDate) : null}
                                    setValue={(v) => updateFromToDate(v, "to")}
                                    minDate={new Date(2010, 0, 1)}
                                    maxDate={new Date(2049, 11, 31)}
                                />
                            </DateInputContainer>
                        </DateInputs>
                    </form>
                </FormProvider>

                {isLoading ? (
                    <SpinnerContainer>
                        <LoadingSpinner />
                    </SpinnerContainer>
                ) : (
                    <>
                        {rowModel.rows.length > 0 ? (
                            <TableStyled>
                                <StandardTableContent
                                    headerGroups={headerGroups}
                                    rowModel={rowModel}
                                    renderRowSubComponent={renderRowSubComponent}
                                />
                            </TableStyled>
                        ) : (
                            <TB>{t("no_downtimes_to_show")}</TB>
                        )}

                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={table.previousPage}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={table.nextPage}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={table.setPageIndex}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    </>
                )}
            </MainArea>
        </>
    );
};

export default DowntimeReports;

const SpinnerContainer = styled.div`
    min-height: 3.3rem;
    padding: 1rem;
`;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
        }
    }

    .summed > div {
        display: flex;
        align-items: baseline;
    }
`;

const DateInputs = styled.section`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

const DateInputContainer = styled.div`
    margin-right: 1.5rem;
`;

const Chevron = styled(LesserThanIcon)`
    transform: rotate(${(p) => (p.$isExpanded ? "90deg" : "-90deg")});
    transition: transform 0.4s ease;
`;
