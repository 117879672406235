import { useMemo } from "react";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { startOfDay, add, format } from "date-fns";
import styled from "styled-components";

import { useTasksForUnit } from "../task/hooks/useTasksForUnit";
import { H2, T } from "../../components/texts";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";
import { TaskStatus } from "../../api/core/taskAPI";

const UnitPlannedMaintenanceTable = ({ unitId }) => {
    const { t } = useTranslation();

    const scheduledTasks = useTasksForUnit(
        startOfDay(new Date()),
        add(startOfDay(new Date()), { months: 12 }),
        unitId,
        [TaskStatus.SCHEDULED, TaskStatus.STARTED]
    );

  const tableData = useMemo(() => {
    const sortedTasks = scheduledTasks?.data?.slice().sort((a, b) =>
            new Date(a.start) - new Date(b.start)
    );
    // Returns only the first 5 tasks
    return sortedTasks?.slice(0, 5) || [];
  }, [scheduledTasks?.data]);

    const columns = useMemo(
        () => [
            {
                header: t("to_the_workshop"),
                accessorKey: "start",
                cell: ({ getValue, row }) => {
                    const value = getValue();
                    return (
                        <span>
                            {format(new Date(value), "dd.MM.yy")}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {format(new Date(value), "HH:mm")}
                            {row.original.end
                                ? ` – ${format(new Date(row.original.end), "HH:mm")}`
                                : ""}
                        </span>
                    );
                },
            },
            {
                header: t("description_2"),
                accessorKey: "description",
                enableSorting: false,
            },
        ],
        []
    );

    const initialState = useMemo(
        () => ({
            sorting: [{ id: "start", desc: false }],
        }),
        []
    );

    const table = useReactTable({
        columns,
        data: tableData,
        initialState,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <Container>
            <H2>{t("upcoming_services")}</H2>

            {rowModel.rows.length > 0 ? (
                <TableContainer>
                    <StandardTableStyle>
                        <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                    </StandardTableStyle>
                </TableContainer>
            ) : (
                <T>{t("no_services_found")}</T>
            )}
        </Container>
    );
};

export default UnitPlannedMaintenanceTable;

const Container = styled.article`
    margin-top: 3.5rem;
`;

const TableContainer = styled.div`
    overflow: auto;
    max-width: 50rem;
`;
