import { useState, useEffect } from "react";
import QRCode from "qrcode";

/**
 * Custom hook to generate a QR code URL from given text.
 * @param {string | QRCode.QRCodeSegment[]} text - The text or QR code segments to encode.
 * @param {QRCode.QRCodeToDataURLOptions} [options] - Optional QR code generation options.
 * @returns {string} The generated QR code URL.
 */
const useQRCode = (text, options) => {
    const [qrCodeUrl, setQrCodeUrl] = useState("");

    useEffect(() => {
        QRCode.toString(text, options).then(setQrCodeUrl).catch(console.error);
    }, [text]);

    return qrCodeUrl;
};

export default useQRCode;
