import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import partAPI from "../../../api/core/partAPI";

export const useUpdatePart = () => {
    const { t } = useTranslation();

    return useMutation(partAPI.updatePart, {
        onError: (error) =>
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            ),
    });
};
