export const PERMISSIONS = {
    system_admin: {
        name: "system_admin",
        category: "System",
        description: "Systemadministrator",
        system: true,
    },
    user_admin: {
        name: "user_admin",
        category: "Administrasjon",
        description: "Administrere brukere",
        system: false,
    },
    unit_view_overview: {
        name: "unit_view_overview",
        category: "Kjøretøy",
        description: "Oversikt",
        system: false,
        is_navbar: true,
    },
    unit_view_status: {
        name: "unit_view_status",
        category: "Kjøretøy",
        description: "Kjøretøysstatus",
        system: false,
        is_navbar: true,
    },
    unit_view_groups: {
        name: "unit_view_groups",
        category: "Kjøretøy",
        description: "Grupper",
        system: false,
        is_navbar: true,
    },
    unit_view_precheck: {
        name: "unit_view_precheck",
        category: "Kjøretøy",
        description: "Førbrukskontroll",
        system: false,
        is_navbar: true,
    },
    unit_admin: {
        name: "unit_admin",
        category: "Kjøretøy",
        description: "Administrere kjøretøy",
        system: false,
    },
    unit_comment_admin: {
        name: "unit_comment_admin",
        category: "Kjøretøy",
        description: "Administrere kommentarer",
        system: false,
    },
    unit_comment_email: {
        name: "unit_comment_email",
        category: "Kjøretøy",
        description: "Få e-post ved ny kommentar",
        system: false,
    },
    unit_use: {
        name: "unit_use",
        category: "Kjøretøy",
        description: "Starte/stoppe bruk av kjøretøy",
        system: false,
    },
    role_admin: {
        name: "role_admin",
        category: "Administrasjon",
        description: "Administrere roller",
        system: false,
    },
    precheck_admin: {
        name: "precheck_admin",
        category: "Administrasjon",
        description: "Administrere førbrukskontroll",
        system: false,
    },
    service_admin: {
        name: "service_admin",
        category: "Administrasjon",
        description: "Administrere service",
        system: false,
    },
    periodic_technical_inspection_admin: {
        name: "periodic_technical_inspection_admin",
        category: "Administrasjon",
        description: "Administrere sakkynding kontroll",
        system: false,
    },
    config_admin: {
        name: "config_admin",
        category: "Administrasjon",
        description: "Administrere konfigurasjon",
        system: false,
    },
    scheduler_view_calendar: {
        name: "scheduler_view_calendar",
        category: "Planlegger",
        description: "Kalender",
        system: false,
        is_navbar: true,
    },
    scheduler_view_taskmanagement: {
        name: "scheduler_view_taskmanagement",
        category: "Planlegger",
        description: "Ordrehåndtering",
        system: false,
        is_navbar: true,
    },
    scheduler_view_recurring_tasks: {
        name: "scheduler_view_recurring_tasks",
        category: "Planlegger",
        description: "Gjentakende oppgaver",
        system: false,
        is_navbar: true,
    },
    scheduler_full: {
        name: "scheduler_full",
        category: "Planlegger",
        description: "Full tilgang til planlegger",
        system: false,
    },
    scheduler_read: {
        name: "scheduler_read",
        category: "Planlegger",
        description: "Lesetilgang til planlegger",
        system: false,
    },
    part_view_requests: {
        name: "part_view_requests",
        category: "Materiell",
        description: "Rekvisisjoner",
        system: false,
        is_navbar: true,
    },
    part_view_inventory: {
        name: "part_view_inventory",
        category: "Materiell",
        description: "Deler",
        system: false,
        is_navbar: true,
    },
    part_view_storage_withdrawal: {
        name: "part_view_storage_withdrawal",
        category: "Materiell",
        description: "Lageruttak",
        system: false,
        is_navbar: true,
    },
    view_inventory_withdrawal_customer: {
        name: "view_inventory_withdrawal_customer",
        category: "Materiell",
        description: "Lageruttak Kunde",
        system: false,
        is_navbar: true,
    },
    part_admin: {
        name: "part_admin",
        category: "Materiell",
        description: "Administrere deler",
        system: false,
    },
    part_checkout: {
        name: "part_checkout",
        category: "Materiell",
        description: "Kan hente ut deler fra delelager",
        system: false,
    },
    mechanic: {
        name: "mechanic",
        category: "Planlegger",
        description: "Mekaniker",
        system: false,
    },
    customer_admin: {
        name: "customer_admin",
        category: "Administrasjon",
        description: "Kan administrere firmaet",
        system: false,
    },
    sensor_admin: {
        name: "sensor_admin",
        category: "Administrasjon",
        description: "Kan opprette sensorer",
        system: true,
    },
    task_pre_check_admin: {
        name: "task_pre_check_admin",
        category: "Administrasjon",
        description: "Administrere forhåndssjekker for aktivitet",
        system: false,
    },
    task_post_check_admin: {
        name: "task_post_check_admin",
        category: "Administrasjon",
        description: "Administrere ettersjekker for aktivitet",
        system: false,
    },
    reports_view_prechecks: {
        name: "reports_view_prechecks",
        category: "Rapporter",
        description: "Førbrukskontroller",
        system: false,
        is_navbar: true,
    },
    reports_view_hourreport: {
        name: "reports_view_hourreport",
        category: "Rapporter",
        description: "Timerapport",
        system: false,
        is_navbar: true,
    },
    reports_view_sja: {
        name: "reports_view_sja",
        category: "Rapporter",
        description: "SJA",
        system: false,
        is_navbar: true,
    },
    reports_view_downtime: {
        name: "reports_view_downtime",
        category: "Rapporter",
        description: "Nedetid",
        system: false,
        is_navbar: true,
    },
    reports_view_deviation_list: {
        name: "reports_view_deviation_list",
        category: "Rapporter",
        description: "Avviksliste",
        system: false,
        is_navbar: true,
    },
    reports_view_unit_overview: {
        name: "reports_view_unit_overview",
        category: "Rapporter",
        description: "Kjøretøysoversikt",
        system: false,
        is_navbar: true,
    },
    reports_view_payroll_export: {
        name: 'reports_view_payroll_export',
        category: 'Rapporter',
        description: 'Lønnseksport',
        system: false,
        is_navbar: true
    },
    dashboard_view: {
        name: "dashboard_view",
        category: "Dashbord",
        description: "Dashbord",
        system: false,
        is_navbar: true,
    },
    administration_users_and_permissions_view: {
        name: "administration_users_view",
        category: "Administrasjon",
        description: "Brukere og tilgang",
        system: false,
        is_navbar: true,
    },
    administration_customers_view: {
        name: "administration_customers_view",
        category: "Administrasjon",
        description: "Firmaer",
        system: false,
        is_navbar: true,
    },
    administration_roles_view: {
        name: "administration_roles_view",
        category: "Administrasjon",
        description: "Roller",
        system: false,
        is_navbar: true,
    },
    administration_setup_view: {
        name: "administration_setup_view",
        category: "Administrasjon",
        description: "Oppsett",
        system: false,
        is_navbar: true,
    },
    part_view_history: {
        name: "part_view_history",
        category: "Materiell",
        description: "Historikk",
        system: false,
        is_navbar: true,
    },
};
