import { Button } from "../buttons";
import useQRCode from "../../features/qr/useQRCode";
import { useRef } from "react";

const qrWidth = 2500;
const qrHeight = 2500;

const UnitQrCode = ({ uuid, int_id }) => {
    const svgRef = useRef(null);
    const qrData = useQRCode(`https://mainflow.no/display/drivers/public/${uuid}`, {
        width: qrWidth,
        height: qrHeight,
        color: {
            light: "#fffff9",
            dark: "#38548d",
        },
        errorCorrectionLevel: "H",
        type: "svg",
        margin: 0,
        rendererOpts: {
            quality: 1,
        },
    });

    return (
        <>
            <svg
                ref={svgRef}
                version="1.0"
                height="300"
                width="285"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 4000 3800"
            >
                <rect width="4000" height="3800" fill="#38548d" />
                <svg dangerouslySetInnerHTML={{ __html: qrData }} x="750" y="200" />

                <svg x="1757" y="1207" width="486" height="486" viewBox="0 0 31 31">
                    <rect width="31" height="31" fill="white" stroke="none" />
                </svg>
                <svg x="1789" y="1239" width="422" height="422" viewBox="0 0 512 512">
                    <rect x="64" y="128" width="384" height="256" fill="white" stroke="none" />

                    <defs>
                        <clipPath id="cut-off-background">
                            <rect
                                x="-3"
                                y="-3"
                                width="518"
                                height="518"
                                rx="120"
                                ry="120"
                                stroke-width="6px"
                                stroke="none"
                                fill="none"
                            />
                        </clipPath>
                    </defs>

                    <g clip-path="url(#cut-off-background)">
                        <g transform="translate(0,512) scale(0.1,-0.1)">
                            <path
                                fill="#38548d"
                                d="M0 2560 l0 -2560 2560 0 2560 0 0 2560 0 2560 -2560 0 -2560 0 0
                -2560z m2575 913 c46 -25 85 -63 111 -113 18 -32 19 -72 24 -529 l5 -495 499
                558 c439 491 505 561 550 582 127 59 272 6 332 -121 l24 -50 0 -937 0 -938
                -250 0 -250 0 -2 582 -3 581 -501 -560 c-359 -400 -512 -565 -540 -579 -29
                -14 -60 -19 -120 -19 -72 0 -86 3 -130 30 -59 37 -108 113 -118 181 -3 26 -6
                306 -6 623 l-1 576 -76 -80 c-244 -258 -459 -585 -725 -1102 l-120 -233 -283
                0 -284 0 45 97 c377 812 856 1490 1269 1796 100 74 221 138 305 162 75 21 193
                15 245 -12z"
                            />
                        </g>
                    </g>
                </svg>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="321"
                    y="2850"
                    width="3358"
                    height="781"
                    viewBox="0 0 3358 781"
                >
                    {/* <!-- Background with rounded corners --> */}
                    <rect
                        x="33"
                        y="28"
                        width="3292"
                        height="715"
                        rx="120"
                        ry="120"
                        stroke="none"
                        fill="#F7F7F7"
                    />

                    <defs>
                        <clipPath id="cut-off-orange">
                            <rect
                                x="44"
                                y="40"
                                width="3270"
                                height="691"
                                rx="120"
                                ry="120"
                                stroke="#38548d"
                                stroke-width="6px"
                                fill="none"
                            />
                        </clipPath>
                    </defs>

                    {/* <!-- Thinner orange bar at the bottom --> */}
                    <rect
                        x="50"
                        y="626"
                        width="3258"
                        height="100"
                        fill="#EB6D3D"
                        clip-path="url(#cut-off-orange)"
                    />

                    <g transform="translate(0,781) scale(0.1,-0.1)" fill="#38548d" stroke="none">
                        {/* <!-- T --> */}
                        <path
                            d="M3550 4890 c-19 -19 -20 -33 -20 -239 l0 -220 26 -20 c25 -20 40 -21
            225 -21 l199 0 0 -674 c0 -600 2 -676 16 -690 13 -14 53 -16 283 -16 215 0
            271 3 287 14 18 14 19 34 22 690 l2 675 206 3 c190 3 207 4 220 22 11 16 14
            66 14 241 0 202 -2 223 -18 238 -17 16 -82 17 -730 17 -699 0 -712 0 -732 -20z"
                        />

                        {/* <!-- R --> */}
                        <path
                            d="M5111 4891 c-21 -21 -21 -23 -21 -934 0 -873 1 -915 18 -930 16 -15
            51 -17 290 -17 269 0 271 0 286 22 14 19 16 67 16 320 l0 298 59 0 59 0 123
            -307 c67 -170 126 -314 132 -320 7 -10 81 -13 308 -13 296 0 298 0 313 22 9
            12 16 29 16 37 0 7 -74 177 -165 377 -91 200 -165 366 -165 369 0 4 33 39 74
            78 120 117 167 233 167 417 1 122 -11 178 -56 275 -67 142 -219 249 -420 297
            -83 19 -124 21 -552 25 -457 5 -462 4 -482 -16z m856 -521 c51 -33 59 -122 16
            -170 -34 -38 -79 -49 -195 -50 l-88 0 0 121 0 121 123 -4 c83 -2 129 -8 144
            -18z"
                        />

                        {/* <!-- U --> */}
                        <path
                            d="M6764 4889 c-18 -20 -19 -45 -18 -618 0 -628 4 -690 50 -836 70 -217
            226 -362 460 -427 70 -20 102 -22 294 -22 190 0 224 3 294 22 210 58 361 175
            444 342 90 183 104 333 100 1031 -3 463 -4 488 -22 508 -19 20 -27 21 -286 21
            -243 0 -268 -2 -283 -18 -14 -16 -16 -84 -19 -627 -4 -657 -3 -650 -57 -708
            -34 -37 -50 -45 -111 -57 -101 -19 -183 10 -224 80 -20 34 -21 52 -26 661 -5
            599 -6 628 -24 648 -19 20 -27 21 -286 21 -259 0 -267 -1 -286 -21z"
                        />

                        {/* <!-- C --> */}
                        <path
                            d="M9210 4934 c-146 -25 -260 -65 -349 -121 -167 -106 -289 -286 -348
            -512 -35 -133 -44 -404 -18 -566 60 -383 250 -628 565 -727 67 -21 94 -23 260
            -23 218 0 296 14 423 77 65 32 100 59 167 127 91 92 137 165 189 301 57 148
            61 145 -247 216 -200 46 -248 54 -268 45 -14 -6 -27 -16 -29 -23 -53 -152 -99
            -208 -186 -228 -125 -28 -213 40 -256 195 -21 78 -32 268 -23 385 11 134 39
            223 87 279 45 53 85 71 159 71 80 0 133 -32 168 -102 14 -28 26 -58 26 -65 0
            -26 29 -70 49 -76 11 -2 126 14 257 37 307 55 298 46 237 241 -72 232 -231
            383 -469 446 -57 15 -119 21 -224 24 -80 2 -156 2 -170 -1z"
                        />

                        {/* <!-- K --> */}
                        <path
                            d="M10210 4890 c-20 -20 -20 -33 -20 -933 0 -873 1 -915 18 -930 16 -15
            51 -17 289 -17 249 0 271 1 286 18 15 16 17 48 17 244 l0 225 66 87 c36 47 69
            86 73 86 3 0 76 -144 161 -321 85 -176 159 -324 166 -330 7 -5 131 -8 315 -7
            l303 3 16 27 c15 27 10 38 -269 596 l-284 569 200 259 c110 142 216 281 236
            307 41 54 47 95 19 120 -16 15 -52 17 -294 17 -152 0 -283 -3 -292 -6 -9 -3
            -105 -132 -214 -286 l-197 -280 -5 272 c-5 254 -6 273 -24 286 -16 11 -72 14
            -283 14 -250 0 -264 -1 -283 -20z"
                        />

                        {/* <!-- O --> */}
                        <path
                            d="M13145 4933 c-273 -36 -506 -212 -620 -468 -86 -195 -119 -502 -80
            -744 62 -382 267 -622 615 -723 30 -9 107 -13 235 -12 187 0 191 1 285 33 372
            126 570 453 570 941 0 292 -87 554 -238 720 -94 103 -222 189 -327 219 -120
            35 -318 50 -440 34z m213 -498 c121 -36 182 -194 182 -475 0 -275 -61 -437
            -175 -469 -81 -22 -163 -8 -216 37 -70 58 -110 213 -110 422 -1 180 20 300 67
            391 18 36 79 88 112 95 15 3 32 7 37 9 22 7 54 4 103 -10z"
                        />

                        {/* <!-- G --> */}
                        <path
                            d="M14860 4930 c-157 -25 -302 -95 -412 -198 -123 -116 -192 -242 -238
            -432 -28 -117 -39 -405 -21 -546 35 -266 116 -451 256 -589 131 -128 278 -185
            480 -185 129 0 236 23 320 68 l53 29 13 -31 14 -31 220 -3 c214 -3 220 -2 238
            19 16 20 17 59 17 499 0 456 -1 479 -19 501 l-19 24 -371 0 c-342 0 -372 -1
            -388 -18 -16 -16 -18 -39 -18 -207 0 -237 -9 -224 150 -228 131 -4 140 -11 80
            -68 -45 -42 -100 -57 -191 -52 -93 4 -134 31 -174 113 -44 91 -62 196 -62 375
            0 300 66 446 207 458 115 9 181 -28 210 -121 26 -79 40 -97 78 -97 67 0 459
            79 478 96 26 23 24 51 -12 158 -78 235 -238 385 -474 447 -90 23 -321 34 -415
            19z"
                        />

                        {/* <!-- M --> */}
                        <path
                            d="M16550 4890 c-20 -20 -20 -33 -20 -933 0 -873 1 -915 18 -930 16 -15
            50 -17 269 -17 229 0 251 1 266 18 15 17 17 55 17 347 0 181 2 326 5 324 2 -3
            39 -135 80 -295 42 -159 80 -306 86 -326 18 -69 16 -68 263 -68 200 0 224 2
            238 18 9 9 53 161 98 337 46 176 87 324 92 330 4 5 8 -140 8 -322 0 -290 2
            -334 16 -347 14 -14 49 -16 272 -14 l256 3 13 25 c10 20 13 218 13 918 0 898
            -1 922 -34 944 -6 4 -161 8 -343 8 -306 0 -334 -1 -350 -18 -11 -11 -67 -197
            -148 -489 -71 -260 -133 -469 -136 -465 -4 4 -62 216 -129 472 -93 356 -126
            469 -142 483 -18 15 -51 17 -354 17 -321 0 -335 -1 -354 -20z"
                        />

                        {/* <!-- A --> */}
                        <path
                            d="M19107 4898 c-12 -9 -101 -303 -279 -923 -243 -847 -260 -911 -246
            -935 l14 -25 272 -3 c168 -1 280 2 291 8 18 9 32 59 98 348 5 22 6 22 197 22
            l192 0 38 -157 c49 -203 45 -190 62 -208 13 -13 59 -15 294 -15 305 0 310 1
            310 59 0 42 -509 1804 -527 1823 -15 17 -44 18 -358 18 -257 0 -345 -3 -358
            -12z m382 -851 c17 -71 31 -130 31 -133 0 -2 -29 -4 -65 -4 -36 0 -65 4 -65 9
            0 24 60 264 65 259 1 -2 17 -61 34 -131z"
                        />

                        {/* <!-- S --> */}
                        <path
                            d="M20867 4924 c-318 -57 -528 -283 -527 -568 1 -200 91 -368 257 -476
            90 -59 166 -90 347 -140 243 -67 310 -98 321 -150 16 -73 -54 -115 -181 -108
            -124 7 -183 54 -219 176 -25 82 -31 82 -263 11 -302 -92 -289 -87 -301 -117
            -9 -22 -8 -38 8 -85 63 -182 191 -334 343 -407 118 -57 236 -80 413 -80 391 0
            660 157 748 435 31 99 31 312 -1 397 -45 125 -146 237 -271 301 -72 38 -158
            67 -354 122 -185 51 -247 85 -247 135 0 40 40 72 97 77 101 10 182 -36 229
            -129 41 -81 43 -81 281 2 205 72 253 97 253 130 0 54 -105 228 -183 302 -159
            153 -467 223 -750 172z"
                        />

                        {/* <!-- KI --> */}
                        <path
                            d="M21970 4890 c-20 -20 -20 -33 -20 -933 0 -873 1 -915 18 -930 16 -15
            51 -17 289 -17 249 0 271 1 286 18 15 16 17 48 17 244 l0 225 66 87 c36 47 69
            86 73 86 3 0 74 -140 156 -311 83 -171 156 -320 163 -330 12 -18 31 -19 316
            -19 204 0 306 4 313 11 8 8 13 8 17 0 5 -8 95 -11 281 -11 l275 0 15 23 c14
            21 15 129 13 936 -3 890 -3 913 -22 927 -16 11 -71 14 -283 14 -250 0 -264 -1
            -283 -20 -20 -20 -20 -34 -21 -882 l0 -863 -266 531 -266 530 200 260 c110
            142 216 281 236 307 41 54 47 95 19 120 -16 15 -52 17 -294 17 -152 0 -283 -3
            -292 -6 -9 -3 -105 -132 -214 -286 l-197 -280 -5 272 c-5 254 -6 273 -24 286
            -16 11 -72 14 -283 14 -250 0 -264 -1 -283 -20z"
                        />

                        {/* <!-- N --> */}
                        <path
                            d="M24460 4890 c-20 -20 -20 -33 -20 -933 0 -873 1 -915 18 -930 16 -15
            50 -17 270 -17 214 0 253 2 266 16 14 13 16 67 16 432 1 365 2 415 15 398 8
            -11 114 -195 236 -410 122 -215 231 -401 242 -413 20 -23 23 -23 288 -23 265
            0 268 0 283 22 14 20 16 125 16 930 0 895 0 908 -20 928 -19 19 -33 20 -268
            20 -228 0 -250 -2 -265 -18 -15 -17 -17 -59 -17 -397 0 -223 -4 -375 -9 -369
            -5 5 -96 169 -202 364 -105 195 -203 370 -216 388 l-24 32 -295 0 c-281 0
            -295 -1 -314 -20z"
                        />

                        {/* <!-- A --> */}
                        <path
                            d="M27267 4898 c-12 -9 -101 -303 -279 -923 -243 -847 -260 -911 -246
            -935 l14 -25 272 -3 c168 -1 280 2 291 8 18 9 32 59 98 348 5 22 6 22 197 22
            l192 0 38 -157 c49 -203 45 -190 62 -208 13 -13 59 -15 294 -15 305 0 310 1
            310 59 0 42 -509 1804 -527 1823 -15 17 -44 18 -358 18 -257 0 -345 -3 -358
            -12z m382 -851 c17 -71 31 -130 31 -133 0 -2 -29 -4 -65 -4 -36 0 -65 4 -65 9
            0 24 60 264 65 259 1 -2 17 -61 34 -131z"
                        />

                        {/* <!-- S --> */}
                        <path
                            d="M29027 4924 c-318 -57 -528 -283 -527 -568 1 -200 91 -368 257 -476
            90 -59 166 -90 347 -140 243 -67 310 -98 321 -150 16 -73 -54 -115 -181 -108
            -124 7 -183 54 -219 176 -25 82 -31 82 -263 11 -302 -92 -289 -87 -301 -117
            -9 -22 -8 -38 8 -85 63 -182 191 -334 343 -407 118 -57 236 -80 413 -80 391 0
            660 157 748 435 31 99 31 312 -1 397 -45 125 -146 237 -271 301 -72 38 -158
            67 -354 122 -185 51 -247 85 -247 135 0 40 40 72 97 77 101 10 182 -36 229
            -129 41 -81 43 -81 281 2 205 72 253 97 253 130 0 54 -105 228 -183 302 -159
            153 -467 223 -750 172z"
                        />
                    </g>

                    <rect
                        x="44"
                        y="40"
                        width="3270"
                        height="691"
                        rx="120"
                        ry="120"
                        stroke="#324F83"
                        stroke-width="6px"
                        fill="none"
                    />
                    <rect
                        x="48"
                        y="44"
                        width="3262"
                        height="683"
                        rx="120"
                        ry="120"
                        stroke="#2A426E"
                        stroke-width="6px"
                        fill="none"
                    />
                </svg>
            </svg>
            <Button
                onClick={() => {
                    if (svgRef?.current == null) return;

                    const svgData = svgRef.current.outerHTML;
                    const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
                    const svgUrl = URL.createObjectURL(svgBlob);

                    const downloadLink = document.createElement("a");
                    downloadLink.href = svgUrl;
                    downloadLink.download = `${int_id}.svg`;
                    downloadLink.click();

                    URL.revokeObjectURL(svgUrl);
                }}
            >
                Last ned
            </Button>
        </>
    );
};

export default UnitQrCode;
