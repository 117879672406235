const LOCAL_STORAGE_USERS = "users";

export const getSavedUsers = () => {
    try {
        const state = localStorage.getItem(LOCAL_STORAGE_USERS);

        const users = JSON.parse(state);
        return users ? users : {};
    } catch (err) {
        console.error("Failed to load users");
        return {};
    }
};

export const addSavedUser = (name, username) => {
    try {
        if (name === " ") return;
        const users = getSavedUsers();
        if (users[name] === username) return;
        users[name] = username;
        const store = JSON.stringify(users);
        localStorage.setItem(LOCAL_STORAGE_USERS, store);
    } catch (err) {
        console.error("Failed to save user");
    }
};

export const deleteSavedUser = (name) => {
    try {
        const users = getSavedUsers();
        delete users[name];
        localStorage.setItem(LOCAL_STORAGE_USERS, JSON.stringify(users));
    } catch (err) {
        console.error("Failed to delete saved user");
    }
};

export const saveLastUsedWorkshopId = async (id) => {
    try {
        localStorage.setItem("lastUsedWorkshopId", id);
    } catch (err) {
        console.error("Failed to save last used workshop id to local storage");
    }
};

export const getLastUsedWorkshopId = async () => {
    try {
        return localStorage.getItem("lastUsedWorkshopId");
    } catch (err) {
        console.error("Failed to get last used workshop id from local storage");
    }
};

export const saveLastUsedWithdrawalWorkshopId = async (id) => {
    try {
        localStorage.setItem("lastUsedWithdrawalWorkshopId", id);
    } catch (err) {
        console.error("Failed to save last used workshop id to local storage");
    }
};

export const getLastUsedWithdrawalWorkshopId = async () => {
    try {
        return localStorage.getItem("lastUsedWithdrawalWorkshopId");
    } catch (err) {
        console.error("Failed to get last used workshop id from local storage");
    }
};
