import {Navigate, Route, Routes} from "react-router-dom";
import styled from "styled-components";

import NavBar from "./components/layout/NavBar";
import Dashboard from "./features/dashboard/Dashboard";
import Scheduler from "./features/scheduler/Scheduler";
import PreCheckCheckout from "./features/pre-check/PreCheckCheckout";
import UnitPreChecklistAnswer from "./features/unit/UnitPreChecklistAnswer";
import UnitUse from "./features/unit/UnitUse";
import SearchUnit from "./features/unit/SearchUnit";
import UnitFinished from "./features/unit/UnitFinished";
import UnitControls from "./features/unit/UnitControls";
import Unit from "./features/unit/Unit";
import Roles from "./features/admin/roles/Roles";
import RoleEdit from "./features/admin/roles/RoleEdit";
import UserEdit from "./features/admin/users/UserEdit";
import Users from "./features/admin/users/Users";
import Customers from "./features/admin/customers/Customers";
import CustomerEdit from "./features/admin/customers/CustomerEdit";
import Units from "./features/admin/units/Units";
import Setup from "./features/admin/setup/Setup";
import UnitEdit from "./features/admin/units/UnitEdit";
import Parts from "./features/materials/Parts";
import PartEdit from "./features/materials/PartEdit";
import ServiceSetup from "./features/admin/service/ServiceSetup";
import PrecheckLists from "./features/admin/setup/PrecheckLists";
import PrecheckList from "./features/admin/setup/PrecheckList";
import UnitGroups from "./features/admin/units/UnitGroups";
import UnitGroup from "./features/admin/units/UnitGroup";
import SchedulerSetup from "./features/admin/setup/SchedulerSetup";
import Profile from "./features/user/Profile";
import Email from "./features/user/Email";
import Password from "./features/user/Password";
import MyTasks from "./features/user/MyTasks";
import Logout from "./features/user/Logout";
import Footer from "./components/layout/Footer";
import PreCheckReports from "./features/report/PreCheckReports";
import PartRequests from "./features/materials/PartRequests";
import TasksOverview from "./features/tasks/TasksOverview";
import UnitsSetup from "./features/admin/setup/UnitsSetup";
import InspectionSetup from "./features/admin/inspection/InspectionSetup";
import HourReports from "./features/report/HourReports/HourReports";
import SensorsList from "./features/sensor/SensorsList";
import SensorEdit from "./features/sensor/SensorEdit";
import UnitStatus from "./features/admin/units/UnitStatus";
import Kitboxes from "./features/admin/kitbox/KitboxesList";
import KitboxEdit from "./features/admin/kitbox/KitboxEdit";
import RecurringTasks from "./features/tasks/RecurringTasks";
import PageNotFound from "./features/page-not-found/PageNotFound";
import TaskPreChecks from "./features/admin/task-pre-checks/TaskPreChecks";
import TaskPostChecks from "./features/admin/task-post-checks/TaskPostChecks";
import SJAReports from "./features/report/SJA/SJAReports";
import InspectionCategories from "./features/admin/inspection/InspectionCategories";
import InspectionConfigSetup from "./features/admin/inspection/InspectionConfigSetup";
import InspectionSetupWithCustomer from "./features/admin/inspection/InspectionSetupWithCustomer";
import InspectionConfigList from "./features/admin/inspection/InspectionConfigList";
import InventoryWithdrawal from "./features/materials/InventoryWithdrawal";
import InventoryWithdrawalCustomer from "./features/materials/InventoryWithdrawalCustomer";
import DowntimeReports from "./features/report/Downtime/DowntimeReports";
import {DeviationReports} from "./features/report/DeviationReports";
import Frontpage from "./features/frontpage/Frontpage";
import VehicleOverview from "./features/report/VehicleOverview/VehicleOverview";
import {usePermissions} from "./features/user/hooks/usePermissions";
import {useMemo} from "react";
import PartHistory from "./features/materials/PartHistory";
import PayrollExport from "./features/report/PayrollExport/PayrollExport";

const PrivateApp = () => {
  const permissions = usePermissions();

  const userPermissionsSet = useMemo(() => {
    const permissionsSet = new Set();
    if (permissions.data == null) return permissionsSet;

    for (const customer_id of Object.keys(permissions.data)) {
      for (const permission of Object.keys(permissions.data[customer_id])) {
        permissionsSet.add(permission);
      }
    }
    return permissionsSet;
  }, [permissions.data]);

  return (
          <>
            <NavBar/>
            <MainArea>
              <Routes>
                {/* SCHEDULER */}
                <Route
                        path="/scheduler/tasks"
                        element={
                          userPermissionsSet.has("scheduler_view_taskmanagement") ? (
                                  <TasksOverview/>
                          ) : null
                        }
                />
                <Route
                        path="/scheduler/calendar/:workshopId?"
                        element={
                          userPermissionsSet.has("scheduler_view_calendar") ? <Scheduler/> : null
                        }
                />
                <Route
                        path="/scheduler/recurring"
                        element={
                          userPermissionsSet.has("scheduler_view_recurring_tasks") ? (
                                  <RecurringTasks/>
                          ) : null
                        }
                />
                <Route
                        path="/scheduler"
                        element={
                          userPermissionsSet.has("scheduler_view_calendar") ? (
                                  <Navigate to="calendar"/>
                          ) : userPermissionsSet.has("scheduler_view_taskmanagement") ? (
                                  <Navigate to="tasks"/>
                          ) : userPermissionsSet.has("scheduler_view_recurring_tasks") ? (
                                  <Navigate to="recurring"/>
                          ) : null
                        }
                />

                {/* PRE-CHECK */}
                <Route path="/pre-check/check-out/:id" element={<PreCheckCheckout/>}/>

                {/* UNIT */}
                <Route
                        path="/unit/:unitId/checks/:id/answer"
                        element={<UnitPreChecklistAnswer/>}
                />
                <Route path="/unit/unituse" element={<UnitUse/>}/>
                <Route path="/unit/search" element={<SearchUnit/>}/>
                <Route path="/unit/finish/:id" element={<UnitFinished/>}/>
                <Route path="/unit/:id/checks" element={<UnitControls/>}/>
                <Route path="/unit/:id" element={<Unit/>}/>

                {/* ADMINISTRATION */}
                <Route path="/administration/roles" element={<Roles/>}/>
                <Route path="/administration/roles/:id/edit" element={<RoleEdit/>}/>
                <Route path="/administration/users/:id/edit" element={<UserEdit/>}/>
                <Route path="/administration/users" element={<Users/>}/>
                <Route path="/administration" element={<Navigate to="setup"/>}/>

                <Route path="/administration/customers" element={<Customers/>}/>
                <Route path="/administration/customers/:id/edit" element={<CustomerEdit/>}/>

                {/* SETUP */}
                <Route path="/administration/setup" element={<Setup/>}/>
                <Route path="/administration/setup/service/*" element={<ServiceSetup/>}/>
                <Route path="/administration/setup/inspection" element={<InspectionSetup/>}>
                  <Route path=":customerId" element={<InspectionSetupWithCustomer/>}>
                    <Route index element={<InspectionConfigList/>}/>
                    <Route path="categories" element={<InspectionCategories/>}/>
                    <Route path=":configId" element={<InspectionConfigSetup/>}/>
                  </Route>
                </Route>
                <Route path="/administration/setup/scheduler" element={<SchedulerSetup/>}/>
                <Route path="/administration/setup/units" element={<UnitsSetup/>}/>
                <Route path="/administration/setup/sensors" element={<SensorsList/>}/>
                <Route path="/administration/setup/sensors/:id/edit" element={<SensorEdit/>}/>
                <Route path="/administration/setup/kitbox" element={<Kitboxes/>}/>
                <Route path="/administration/setup/kitbox/:id/edit" element={<KitboxEdit/>}/>
                <Route
                        path="/administration/setup/task-pre-checks"
                        element={<TaskPreChecks/>}
                />
                <Route
                        path="/administration/setup/task-post-checks"
                        element={<TaskPostChecks/>}
                />

                {/* USER */}
                <Route path="/user/profile" element={<Profile/>}/>
                <Route path="/user/email" element={<Email/>}/>
                <Route path="/user/password" element={<Password/>}/>
                <Route path="/user/mytasks" element={<MyTasks/>}/>
                <Route path="/user/logout" element={<Logout/>}/>
                <Route path="/user" element={<Navigate to="mytasks"/>}/>

                {/* REPORTS */}
                <Route
                        path="/reports/prechecks"
                        element={
                          userPermissionsSet.has("reports_view_prechecks") ? (
                                  <PreCheckReports/>
                          ) : null
                        }
                />
                <Route
                        path="/reports/hours"
                        element={
                          userPermissionsSet.has("reports_view_hourreport") ? (
                                  <HourReports/>
                          ) : null
                        }
                />
                <Route
                        path="/reports/payroll-export"
                        element={
                                userPermissionsSet.has("reports_view_payroll_export") ? (
                                <PayrollExport/>
                          ) : null
                        }
                />
                <Route
                        path="/reports/sja"
                        element={userPermissionsSet.has("reports_view_sja") ? <SJAReports/> : null}
                />
                <Route
                        path="/reports/downtime"
                        element={
                          userPermissionsSet.has("reports_view_downtime") ? (
                                  <DowntimeReports/>
                          ) : null
                        }
                />
                <Route
                        path="/reports"
                        element={
                          userPermissionsSet.has("reports_view_prechecks") ? (
                                  <Navigate to="prechecks"/>
                          ) : userPermissionsSet.has("reports_view_hourreport") ? (
                                  <Navigate to="hours"/>
                          ) : userPermissionsSet.has("reports_view_sja") ? (
                                  <Navigate to="sja"/>
                          ) : userPermissionsSet.has("reports_view_downtime") ? (
                                  <Navigate to="downtime"/>
                          ) : userPermissionsSet.has("reports_view_deviation_list") ? (
                                  <Navigate to="comments"/>
                          ) : userPermissionsSet.has("reports_view_unit_overview") ? (
                                  <Navigate to="vehicle-overview"/>
                          ) : null
                        }
                />
                <Route
                        path="/reports/comments"
                        element={
                          userPermissionsSet.has("reports_view_deviation_list") ? (
                                  <DeviationReports/>
                          ) : null
                        }
                />
                <Route
                        path="/reports/vehicle-overview"
                        element={
                          userPermissionsSet.has("reports_view_unit_overview") ? (
                                  <VehicleOverview/>
                          ) : null
                        }
                />

                {/* MATERIALS */}
                <Route
                        path="/materials/parts"
                        element={userPermissionsSet.has("part_view_inventory") ? <Parts/> : null}
                />
                <Route path="/materials/parts/:id/edit" element={<PartEdit/>}/>
                <Route
                        path="/materials/requests"
                        element={
                          userPermissionsSet.has("part_view_requests") ? <PartRequests/> : null
                        }
                />
                <Route
                        path="/materials/inventory-withdrawal"
                        element={
                          userPermissionsSet.has("part_view_storage_withdrawal") ? (
                                  <InventoryWithdrawal/>
                          ) : null
                        }
                />
                <Route
                        path="/materials/inventory-withdrawal-customer"
                        element={
                          userPermissionsSet.has("view_inventory_withdrawal_customer") ? (
                                  <InventoryWithdrawalCustomer/>
                          ) : null
                        }
                />
                <Route
                        path="/materials/history"
                        element={
                          userPermissionsSet.has("part_view_history") ? <PartHistory/> : null
                        }
                />
                <Route
                        path="/materials"
                        element={
                          userPermissionsSet.has("part_view_requests") ? (
                                  <Navigate to="requests"/>
                          ) : userPermissionsSet.has("part_view_inventory") ? (
                                  <Navigate to="parts"/>
                          ) : userPermissionsSet.has("part_view_storage_withdrawal") ? (
                                  <Navigate to="inventory-withdrawal"/>
                          ) : userPermissionsSet.has("part_view_history") ? (
                                  <Navigate to="history"/>
                          ) : null
                        }
                />

                {/* UNITS */}
                <Route
                        path="/units/all"
                        element={userPermissionsSet.has("unit_view_overview") ? <Units/> : null}
                />
                <Route path="/units/:id/edit" element={<UnitEdit/>}/>
                <Route
                        path="/units/status/*"
                        element={userPermissionsSet.has("unit_view_status") ? <UnitStatus/> : null}
                />
                <Route
                        path="/units/groups"
                        element={userPermissionsSet.has("unit_view_groups") ? <UnitGroups/> : null}
                />
                <Route path="/units/groups/:id/edit" element={<UnitGroup/>}/>
                <Route
                        path="/units/prechecks"
                        element={
                          userPermissionsSet.has("unit_view_precheck") ? <PrecheckLists/> : null
                        }
                />
                <Route path="/units/prechecks/:id/edit" element={<PrecheckList/>}/>
                <Route
                        path="/units"
                        element={
                          userPermissionsSet.has("unit_view_overview") ? (
                                  <Navigate to="all"/>
                          ) : userPermissionsSet.has("unit_view_status") ? (
                                  <Navigate to="status"/>
                          ) : userPermissionsSet.has("unit_view_groups") ? (
                                  <Navigate to="groups"/>
                          ) : userPermissionsSet.has("unit_view_precheck") ? (
                                  <Navigate to="prechecks"/>
                          ) : null
                        }
                />

                <Route path="/" element={<Frontpage/>}/>
                <Route
                        path="/dashboard"
                        element={userPermissionsSet.has("dashboard_view") ? <Dashboard/> : null}
                />

                <Route path="*" element={<PageNotFound/>}/>
              </Routes>
            </MainArea>

            <Footer/>
          </>
  );
};

export default PrivateApp;

const MainArea = styled.div`
  min-height: calc(100vh - calc(${(props) => props.theme.footer.height} + 3rem));
  width: 100%;
  background-clip: content-box;
  padding-bottom: 3.125rem;
`;
