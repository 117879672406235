import { useState, useEffect, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getFacetedUniqueValues,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { endOfDay, formatISO, isValid, startOfDay } from "date-fns";
import styled from "styled-components";
import Select from "react-select";

import QueryWrapper from "../../components/application/QueryWrapper";

import MainArea from "../../components/layout/MainArea";
import { H1, TB } from "../../components/texts";
import { CleanButton } from "../../components/buttons";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import TaskEdit from "../task/TaskEdit";
import { standardDateOnly } from "../../common/date";
import { Horizontal } from "../../components/layout/FlexGrid";
import Spacer from "../../components/helpers/Spacer";
import { useHistory } from "../task/hooks/useHistory";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";
import { DateInput, InputWithLabel } from "../../components/inputs";
import SelectStyled from "../../components/application/styles/SelectStyled";
import { useSearchParams } from "../application/hooks/useSearchParams";

const selectFilter = ({ getValue }, columnId, value) => getValue(columnId) === value;

const PartHistory = () => {
    const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);

    const { t } = useTranslation();
    const methods = useForm();

    const tasks = useHistory();

    const location = useLocation();
    const { getParam, setParam, deleteParam, hasParam, queryParams } = useSearchParams();

    const fromParam = getParam("from");
    const toParam = getParam("to");

    const search = methods.watch("search");
    const statusFilterValue = methods.watch("status")?.value || "all_open_orders";

    const [columnFilters, setColumnFilters] = useState([]);
    useEffect(() => {
        const newFilter = [];
        if (hasParam("unit_int_id")) {
            const value = getParam("unit_int_id");
            if (value) {
                newFilter.push({
                    id: "unit_int_id",
                    value,
                });
            }
        }

        if (hasParam("unit_manufacturer")) {
            const value = getParam("unit_manufacturer");
            if (value) {
                newFilter.push({
                    id: "unit_manufacturer",
                    value,
                });
            }
        }

        if (hasParam("unit_type")) {
            const value = getParam("unit_type");
            if (value) {
                newFilter.push({
                    id: "unit_type",
                    value,
                });
            }
        }

        setColumnFilters(newFilter);
    }, [location]);

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 30,
            },
            filters: [...queryParams.entries()]
                .filter(
                    (entry) => entry[0] !== "workshop_id" && entry[0] !== "status" && !!entry[1]
                )
                .map((entry) => ({ id: entry[0], value: entry[1] || undefined })),
        }),
        []
    );

    const data = useMemo(() => {
        if (!tasks?.data || !tasks.data.length) return [];

        return tasks.data.reduce((allRequestedParts, task) => {
            if (
                fromParam &&
                (task.start == null ||
                    startOfDay(new Date(task.start)) < startOfDay(new Date(fromParam)))
            ) {
                return allRequestedParts;
            }
            if (
                toParam &&
                (task.start == null || endOfDay(new Date(task.start)) > endOfDay(new Date(toParam)))
            ) {
                return allRequestedParts;
            }

            for (const part of task.parts || []) {
                const count = part?.count || 1;
                allRequestedParts.push({
                    ...task,
                    requestedPart: {
                        ...part,
                        count,
                        cost: part?.part?.price ? part.part.price * count : null,
                    },
                });
            }

            return allRequestedParts;
        }, []);
    }, [tasks?.data, statusFilterValue, fromParam, toParam]);

    // Add request ids to row checkbox values
    useEffect(() => {
        const allRequestIds =
            data?.map((part) => ({
                requestId: part.requestedPart.id,
                checked: false,
            })) || [];
        methods.setValue("checkboxes", allRequestIds);
    }, [data]);

    function SelectColumnFilter({ column }) {
        const { id, getFacetedUniqueValues, getFilterValue } = column;
        const uniqueColumnKeys = Array.from(getFacetedUniqueValues().keys())
            .filter((key) => key?.length > 0)
            .map((key) => ({
                label: key,
                value: key,
            }));
        uniqueColumnKeys.unshift({ label: t("all"), value: "" });

        const filterValue = getFilterValue();
        const currentValue = uniqueColumnKeys.find((item) => item.value === filterValue);
        return (
            <SelectStyled
                style={{
                    marginTop: "0.2rem",
                    marginBottom: "0.2rem",
                }}
            >
                <Select
                    defaultValue={uniqueColumnKeys[0]}
                    value={currentValue || undefined}
                    onChange={(e) => {
                        setParam(id, e.value);
                    }}
                    options={uniqueColumnKeys}
                    classNamePrefix="rs"
                    name={id}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
            </SelectStyled>
        );
    }

    const columns = useMemo(
        () => [
            {
                header: t("date"),
                accessorKey: "start",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value && isValid(new Date(value)) ? standardDateOnly(value) : "";
                },
            },
            {
                header: t("order"),
                accessorKey: "workorder_id",
                cell: ({ getValue, row }) => (
                    <CleanButton type="button" onClick={() => openEditorForTask(row.original.id)}>
                        <TB $link>{getValue()}</TB>
                    </CleanButton>
                ),
            },
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
                filterFn: "select",
                filterElement: (context) => <SelectColumnFilter {...context} />,
            },
            {
                header: t("brand"),
                accessorKey: "unit.manufacturer",
                filterElement: (context) => <SelectColumnFilter {...context} />,
            },
            {
                header: t("type"),
                accessorKey: "unit.type",
                filterElement: (context) => <SelectColumnFilter {...context} />,
            },
            {
                header: t("part_name"),
                accessorKey: "requestedPart.part.name",
                cell: ({ row, getValue }) => {
                    const partId = row.original.requestedPart.part_id;
                    return (
                        <Link to={`/materials/parts/${partId}/edit`}>
                            <TB $link>{getValue()}</TB>
                        </Link>
                    );
                },
            },
            {
                header: t("description_2"),
                accessorKey: "requestedPart.part.description",
                id: "description",
                enableSorting: false,
            },
            {
                header: t("quantity"),
                accessorKey: "requestedPart.count",
                enableSorting: false,
            },
            {
                header: t("cost"),
                id: "cost",
                accessorKey: "requestedPart.cost",
                cell: ({ getValue }) => {
                    return getValue() || "–";
                },
                enableSorting: false,
            },
            {
                header: t("supplier"),
                id: "supplier",
                accessorKey: "requestedPart.part.supplier_id",
                cell: ({ getValue }) => {
                    return getValue() || "–";
                },
                enableSorting: false,
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        initialState,
        state: {
            columnFilters,
        },
        filterFns: {
            select: selectFilter,
        },
        autoResetAll: false,
        autoResetGlobalFilter: false,
        autoResetSortBy: false,
        autoResetPageIndex: false,
        onColumnFiltersChange: setColumnFilters,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function openEditorForTask(id) {
        setEditTaskId(id);
        setTaskEditorInitialData(null);
        setIsTaskEditorOpen(true);
    }

    function closeTaskEditor(data = null) {
        setEditTaskId(null);
        setTaskEditorInitialData(data);
        setIsTaskEditorOpen(false);
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setIsTaskEditorOpen(true), 50);
    }

    function updateFromToDate(newDate, key) {
        const formattedDate =
            newDate instanceof Date ? formatISO(newDate, { representation: "date" }) : null;

        if (formattedDate) {
            setParam(key, formattedDate);
        } else {
            deleteParam(key);
        }
    }

    return (
        <MainArea>
            <Header>
                <H1>{t("used_materials")}</H1>
                <Spacer />
            </Header>

            <FormProvider {...methods}>
                <form>
                    <InputsContainer>
                        <SearchInput
                            name="search"
                            label={t("search_materials")}
                            style={{ maxWidth: "27rem" }}
                        />

                        <DateInputContainer>
                            <DateInput
                                name="from"
                                label={t("from_start_time")}
                                value={fromParam ? new Date(fromParam) : null}
                                setValue={(v) => updateFromToDate(v, "from")}
                            />
                        </DateInputContainer>
                        <DateInputContainer>
                            <DateInput
                                name="to"
                                label={t("to_start_time")}
                                value={toParam ? new Date(toParam) : null}
                                setValue={(v) => updateFromToDate(v, "to")}
                            />
                        </DateInputContainer>
                    </InputsContainer>

                    <QueryWrapper data={[tasks]}>
                        <TableContainer>
                            <TableStyled>
                                <StandardTableContent
                                    headerGroups={headerGroups}
                                    rowModel={rowModel}
                                />
                            </TableStyled>
                        </TableContainer>
                        {rowModel.rows.length < 1 ? (
                            <NothingToShow>
                                <TB>{t("no_requisitions_to_show")}</TB>
                            </NothingToShow>
                        ) : null}
                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={table.previousPage}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={table.nextPage}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={table.setPageIndex}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    </QueryWrapper>
                </form>
            </FormProvider>

            {isTaskEditorOpen && (
                <TaskEdit
                    editTaskId={editTaskId}
                    isOpen={isTaskEditorOpen}
                    onClose={closeTaskEditor}
                    initialData={taskEditorInitialData}
                    resetTaskEditor={resetTaskEditor}
                />
            )}
        </MainArea>
    );
};

export default PartHistory;

const Header = styled(Horizontal)`
    align-items: center;
    flex-wrap: wrap;
`;

const TableContainer = styled.article`
    margin-top: 1rem;
    overflow: auto;
`;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:nth-child(8),
        th:nth-child(9) {
            text-align: right;
        }

        td:nth-child(8),
        td:nth-child(9) {
            text-align: right;
            padding-right: 2rem;
        }
    }
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const SearchInput = styled(InputWithLabel)`
    flex-grow: 1;
    max-width: 27rem;
    margin-right: 1rem;
`;

const InputsContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
`;

const DateInputContainer = styled.div`
    margin-right: 1rem;
`;
